import { OverlayState, ONotification, AppNavItem } from '@/store';

export const notificationEnqueue = (
  state: OverlayState,
  notification: ONotification,
): void => {
  state.notifications.push(notification);
};

export const notificationDequeue = (state: OverlayState): void => {
  state.notifications.shift();
};

export const isReadySet = (state: OverlayState, isReady: boolean): void => {
  state.isReady = isReady;
};

export const alert = (
  state: OverlayState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { options, resolve, reject }: any,
): void => {
  if (state.dialog.show) {
    return reject('dialog is already open');
  }

  options.type = 'alert';

  state.dialog = {
    show: true,
    options,
    resolve,
    reject,
  };
};

export const confirm = (
  state: OverlayState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { options, resolve, reject }: any,
): void => {
  if (state.dialog.show) {
    return reject('dialog is already open');
  }

  options.type = 'confirm';

  state.dialog = {
    show: true,
    options,
    resolve,
    reject,
  };
};

export const modal = (
  state: OverlayState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { options, resolve, reject }: any,
): void => {
  if (state.dialog.show) {
    return reject('dialog is already open');
  }

  options.type = 'modal';

  state.dialog = {
    show: true,
    options,
    resolve,
    reject,
  };
};

export const dialogDestroy = (state: OverlayState): void => {
  state.dialog = { ...state.dialog, show: false };
};

export const favicon = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: OverlayState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  { favicon }: any,
): void => {
  const link: HTMLLinkElement =
    document.querySelector("link[rel='icon']") ||
    document.createElement('link');
  link.type = 'image/svg+xml';
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const updateDialogByNavItem = (
  state: OverlayState,
  navItem: AppNavItem,
): void => {
  state.dialogByNavItem = navItem;
};

export const cleareDialogByNavItem = (state: OverlayState): void => {
  state.dialogByNavItem = {} as AppNavItem;
};
